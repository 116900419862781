import React from "react";
import portrait from '../assets/images/rotatedevice.gif';
import '../App.css'
const RotateScreen = () => {
  return (
    <div id="mobile-portrait" class="">
    <img src={portrait} alt="" class="center-device" />
  </div>
  );
  }

export default RotateScreen;
